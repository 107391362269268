.header1 {
  background-image: url(../images/charte/header1.jpg);
  background-size: cover;
  background-repeat: no-repeat;

  @media(min-width: $screen-md-min) {
    height: 35rem;

  }
}

.bg-2 {
  background-image: url(../images/charte/bg-2.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
}

.bg-1 {
  background-image: url(../images/charte/bg-1.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
}

.divider {
	height: 20px;
	background: url(../images/charte/divider.png) no-repeat center;
	max-width: 400px;
	margin: 10px auto;
}

.divider-2 {
	height: 20px;
	background: url(../images/charte/divider-2.png) no-repeat center;
	max-width: 400px;
	margin: 10px auto;
}

.border-card {
  border: 1px solid $brand-primary;
}

.ta {
	@extend .text-left;
	margin: 0 0 20px 0;
	padding: 0 0 25px 0;
	position: relative;
	color: $brand-success;
	text-transform: uppercase;
	;

	&::after {
		content: '';
		position: absolute;
		bottom: 0;
		left: 0;
		width: 4rem;
		height: 4px;
		background-color: $brand-success;
	}
}


.ta-inverse {
	@extend .text-left;
	@extend .ta;

	color: white;


	&::after {
		background-color: white;
	}
}


.s2 {



	.block1 {
		@extend .text-justify;
		padding: 25px 5px;
		position: relative;

		background: $brand-success;
		color: white;

		&::after{
			position: absolute;
			content: '';
		
			background: url(../images/charte/transition1.png);
			width: 33px;
			top: 0;
			bottom: 0;
			right: -33px;
		}

		.transitionhaut{
			position: absolute;
			top: -25px;
			left: 0;
			right: -10px;
			height: 25px;
			background: url(../images/charte/transitionhaut.png) right;

		}

		.transitionbas{
			position: absolute;
			bottom: -25px;
			left: 0;
			right: -10px;
			height: 25px;
			background: url(../images/charte/transitionhaut.png) right;
			transform: rotateX(180deg);
		}

	}

	.block2 {
		@extend .text-justify;
		padding: 25px 5px;
		position: relative;
	
		background: $brand-success;
		color: white;

		&::after{
			content: '';
			position: absolute;
		
			background: url(../images/charte/transition3.png);
			width: 33px;
			top: 0;
			bottom: 0;
			left: -33px;
		}

		.transitionhaut{
			position: absolute;
			top: -25px;
			right: 0;
			left: -10px;
			height: 25px;
			background: url(../images/charte/transitionhaut2.png) left;

		}

		.transitionbas{
			position: absolute;
			bottom: -25px;
			right: 0;
			left: -10px;
			height: 25px;
			background: url(../images/charte/transitionhaut2.png) left;
			transform: rotateX(180deg);
		}

	}

  img{
    @extend .margin-image;
    @extend .votreClass;
    border-radius:5px;
    box-shadow: 0 0 35px rgba(0, 0, 0, 0.4);
    }
  
  }